import { ObjectType } from '@/app/constants/typedef';
import { CaptionForPosts } from '../captions/CaptionForPosts';
import { FeedModel } from '../feeds/FeedModel';
import { IPost } from '../writing/WritingPosts';
import UserModel from '../user/UserModel';
import { Media } from '../media/MediaModel';
import { Reply } from '../feeds/ReplyModel';
import { IGroup } from '../group/IGroup';

class PostModel extends FeedModel implements IPost {
    public caption: string | CaptionForPosts;
    public isLike: boolean;
    public isLeader: boolean;
    public isBookmark: boolean;
    public bookmarkCount: number;
    public isEdited: boolean;
    public comment?: Reply | null;
    public group?: IGroup | null;

    // public allowComments: boolean;

    constructor(obj: ObjectType) {
        super(obj);
        this.comment = obj.comment ? new Reply(obj.comment) : null;
        this.caption = obj.caption;
        this.isEdited = obj.is_edited;
        this.id = obj.id;
        this.isPublic = obj.is_public;
        this.isLeader = obj.is_leader;
        this.isLike = obj.is_like;
        this.isBookmark = obj.is_bookmark;
        this.createdAt = obj.created_at;
        this.updatedAt = obj.updated_at;
        this.bookmarkCount = obj.bookmark_count ?? 0;
        this.group = obj.group;
        // this.allowComments = obj.allow_comments;
        this.likeCount = obj.like_count ?? 0;
        this.commentCount = obj.comment_count ?? 0;
        this.media = obj.media.map((me: ObjectType) => new Media(me));
    }
}

export { PostModel };

import { ObjectType } from '@/app/constants/typedef';
import { DateType } from '../date';

abstract class DataModel {
    public createdAt: DateType;

    static field = {
        id: 'id',
        createdAt: 'createdAt',
    };

    constructor(obj: ObjectType) {
        this.createdAt = obj?.created_at;
    }
}

export default DataModel;

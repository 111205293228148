import { ObjectType } from '@/app/constants/typedef';
import DataModel from '../abstract/DataModel';
import UserModel from '../user/UserModel';
import { Media } from '../media/MediaModel';
import { DateType } from '../date';
import { CaptionForChallenges } from '../captions/CaptionForChallenges';

export interface IFeeds {
    id: string;
    owner: UserModel | null;
    isPublic: boolean;
    createdAt: DateType;
    updatedAt: DateType;
    likeCount: number;
    commentCount: number;
    media: Media[];
}

class FeedModel extends DataModel implements IFeeds {
    public id: string;
    public owner: UserModel | null;
    public isPublic: boolean;
    public createdAt: DateType;
    public updatedAt: DateType;
    public likeCount: number;
    public commentCount: number;
    public media: Media[];

    constructor(obj: ObjectType) {
        super(obj.updated_at);
        this.id = obj.id;
        this.owner = obj?.owner ? new UserModel(obj.owner) : null;
        // this.captions = obj.captions;
        this.isPublic = obj.is_public;
        this.createdAt = obj.created_at;
        this.updatedAt = obj.updated_at;
        this.likeCount = obj.like_count;
        this.commentCount = obj.comment_count;
        this.media = obj.media.map((me: ObjectType) => new Media(me));
    }
}

export { FeedModel };

import { ObjectType } from '@/app/constants/typedef';
import DataModel from '../abstract/DataModel';
import { IMedia } from './IMedia';
import { DateType } from '../date';

export class Media extends DataModel implements IMedia {
    public id: string;
    public mediaType: 'image' | 'video' | 'file' | 'url';
    public mediaUrl: string | undefined;
    public mediaLgUrl: string | undefined;
    public mediaMdUrl: string | undefined;
    public mediaSmUrl: string | undefined;
    public updatedAt?: DateType;
    public fileName?: string | undefined;
    public fileSize?: number;
    public order: number;
    constructor(data: ObjectType) {
        super(data);
        this.id = data.id;
        this.mediaType = data.media_type;
        this.fileName = data?.file_name;
        this.fileSize = data?.file_size;
        this.mediaUrl = data.media_url ?? data?.media_lg_url;
        this.mediaLgUrl = data.media_lg_url ?? data?.media_url;
        this.mediaMdUrl = data.media_lg_url ?? data?.media_url;
        this.mediaSmUrl = data.media_lg_url ?? data?.media_url;
        this.updatedAt = data.updated_at;
        this.order = data.order;
    }
}

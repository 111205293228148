import { AxiosResponse } from 'axios';
import axios from './axios';

const fetchWrapper = {
    get: _get,
    refresh_token: _refresh_token,
    post: _post,
    postBinFile: _postBinFile,
    put: _put,
    delete: _delete,
    patch: _patch,
};

async function _refresh_token(url: string, options: any = {}): Promise<AxiosResponse> {
    try {
        const response = await axios({
            method: 'GET',
            url,
            ...options,
        });
        return response.data;
    } catch (error: any) {
        if (error?.status === 401 && error?.data) {
            throw error?.data;
        }
        throw error;
    }
}

async function _get(url: string, options: any = {}): Promise<AxiosResponse> {
    try {
        const response = await axios({
            method: 'GET',
            url,
            ...options,
        });
        return response.data;
    } catch (error: any) {
        if (error?.status === 401 && error?.data) {
            throw error?.data;
        }
        throw error;
    }
}

async function _post(url: string, body?: any, options: any = {}): Promise<AxiosResponse> {
    try {
        const response = await axios({
            url,
            data: body,
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            ...options,
        });

        return response;
    } catch (error: any) {
        if (error?.status === 401 && error?.data) {
            throw error?.data;
        }
        throw error;
    }
}

async function _delete(url: string, options: any = {}): Promise<AxiosResponse> {
    try {
        const response = await axios({
            url,
            method: 'DELETE',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            ...options,
        });

        return response;
    } catch (error: any) {
        if (error?.status === 401 && error?.data) {
            throw error?.data;
        }
        throw error;
    }
}

async function _postBinFile(url: string, attachedFile: File, token: string | undefined | null, responseHandler: (response: Response) => void, options: any = {}) {
    const response = await fetch(
        url,
        token
            ? {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/binary',
                      Authorization: 'Bearer ' + token,
                  },
                  body: attachedFile,
                  ...options,
              }
            : {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/binary',
                  },
                  body: attachedFile,
                  ...options,
              }
    );

    responseHandler(response);
}

async function _put(url: string, body: string, token: string | undefined | null, handleResponse: (response: Response) => void, options: any = {}) {
    const response = await fetch(
        url,
        token
            ? {
                  method: 'PUT',
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + token,
                  },
                  body: JSON.stringify(body),
                  ...options,
              }
            : {
                  method: 'PUT',
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(body),
                  ...options,
              }
    );
    return handleResponse(response);
}

async function _patch(url: string, body?: any, options: any = {}): Promise<AxiosResponse> {
    // Convert body to application/x-www-form-urlencoded format
    try {
        const urlEncodedBody = new URLSearchParams(body).toString();
        const response = await axios({
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            url,
            data: urlEncodedBody,
            ...options,
        });
        return response;
    } catch (error: any) {
        if (error?.status === 401 && error?.data) {
            throw error?.data;
        }
        throw error;
    }
}

export default fetchWrapper;

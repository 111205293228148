'use client';

import { ReactNode, createContext, useContext, useState, FC } from 'react';

interface ConfirmPopUpState {
    title: string;
    message: string;
    acceptActions?: () => void;
    cancelActions?: () => void;
    showCloseButton?: boolean;
    showAcceptButton?: boolean;
}

interface AlertDialogActions {
    open: ({
        title,
        message,
        acceptActions,
        cancelActions,
        showCloseButton,
        showAcceptButton,
    }: {
        title?: string;
        message?: string;
        acceptActions?: () => void;
        cancelActions?: () => void;
        showCloseButton?: boolean;
        showAcceptButton?: boolean;
    }) => void;
    close: () => void;
}

interface UseAuthenticationPopUpContextType {
    isDialogOpen: boolean;
    confirmPopUp: ConfirmPopUpState & AlertDialogActions;
}

const AuthenticationPopUpContext = createContext<UseAuthenticationPopUpContextType | undefined>(undefined);

interface GlobalDialogConfirmAuthenticatedProviderProps {
    children: ReactNode;
}

const defaultTitle = '기간이 만료되었습니다. 계속하려면 다시 로그인하세요로그인 토큰이 만료되었습니다.<br /> <br />로그인 화면으로 이동하시겠어요?';
const defaultMessage = ``;

export const GlobalDialogConfirmAuthenticatedProvider: FC<GlobalDialogConfirmAuthenticatedProviderProps> = ({ children }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [alertDialogState, setAlertDialogState] = useState<ConfirmPopUpState>({
        title: defaultTitle,
        message: defaultMessage,
        showCloseButton: true,
        showAcceptButton: true,
    });

    const confirmDialogActions: AlertDialogActions = {
        open: ({ title, message, acceptActions, cancelActions, showCloseButton, showAcceptButton }) => {
            setAlertDialogState({
                title: title || defaultTitle,
                message: message || defaultMessage,
                acceptActions: acceptActions || undefined,
                cancelActions: cancelActions || undefined,
                showCloseButton: showCloseButton ?? true,
                showAcceptButton: showAcceptButton ?? true,
            });
            setIsDialogOpen(true);
        },
        close: () => {
            setAlertDialogState({
                title: defaultTitle,
                message: defaultMessage,
            });
            setIsDialogOpen(false);
        },
    };

    return (
        <AuthenticationPopUpContext.Provider
            value={{
                isDialogOpen,
                confirmPopUp: { ...alertDialogState, ...confirmDialogActions },
            }}
        >
            {children}
        </AuthenticationPopUpContext.Provider>
    );
};

export function useAuthenticationPopUp(): UseAuthenticationPopUpContextType {
    const context = useContext(AuthenticationPopUpContext);
    if (context === undefined) {
        throw new Error('useAuthenticationPopUp must be used within a GlobalDialogConfirmAuthenticatedProvider');
    }

    return context;
}

'use client';
import utilsService from '@/app/helpers/utilsService';
import fetchWrapper from '../fetchWrapper';

const BASE_URL = process.env.BASE_URL;

interface GetListChallengesParams {
    cursor?: string | null;
    perPage?: number;
    signal?: AbortSignal;
    condition: string;
}

interface getChallengeDetails {
    challengeId: string;
    sortBy?: string;
    sortOrder?: string;
    signal?: AbortSignal;
}

interface temporaryDataByStep {
    challengeId: string;
    step: string | number;
    data: any;
    signal?: AbortSignal;
}

async function GetListChallenges({ cursor = null, perPage = 20, condition, signal }: GetListChallengesParams): Promise<any> {
    try {
        const basePath = 'challenges';
        const params = {
            [condition]: condition ? 1 : 0,
            cursor: cursor,
            per_page: perPage,
        };
        const url = utilsService.constructUrlWithParams(basePath, params);
        const response = await fetchWrapper.get(url, { signal });
        return response;
    } catch (error) {
        throw error;
    }
}

async function getChallengeDetails({ challengeId, sortBy, sortOrder, signal }: getChallengeDetails): Promise<any> {
    try {
        const response = await fetchWrapper.get(`${BASE_URL}/challenges/${challengeId}`, { signal });
        return response;
    } catch (error) {
        throw error;
    }
}

async function getChallengesParticipant(signal?: AbortSignal) {
    try {
        const response = await fetchWrapper.get(`${BASE_URL}/challenges/participant`, { signal });
        return response;
    } catch (error) {
        throw error;
    }
}

async function saveTemporaryByStep({ challengeId, data, signal }: temporaryDataByStep): Promise<any> {
    try {
        const response = await fetchWrapper.post(`${BASE_URL}/challenges/${challengeId}/temporary`, await utilsService.appendToFormData(data), { signal });
        return response;
    } catch (error) {
        throw error;
    }
}

async function getParticipantEd({ challengeId, signal }: { challengeId: string; signal?: AbortController }): Promise<any> {
    try {
        const response = await fetchWrapper.get(`${BASE_URL}/challenges/${challengeId}/participanted`, { signal });
        return response;
    } catch (error) {
        throw error;
    }
}

async function getOptionsSubmitWorkByType({ type, signal }: { type?: 'shape_paper_used' | 'target_age' | 'basic_type' | 'completed_state' | ''; signal?: AbortSignal }): Promise<any> {
    try {
        const basePath = 'submissions/commons';
        const params = {
            common_challenge: type,
        };

        const url = utilsService.constructUrlWithParams(basePath, params);

        const response = await fetchWrapper.get(url, { signal });
        return response.data;
    } catch (error) {
        throw error;
    }
}

async function deleteDraftWork({ id, signal }: { id: string; signal?: AbortSignal }): Promise<any> {
    try {
        const response = await fetchWrapper.delete(`${BASE_URL}/submissions/${id}`, { signal });
        return response.data;
    } catch (error) {
        throw error;
    }
}

async function submitWork({ data, id, signal }: { data: any; id: string; signal?: AbortSignal }): Promise<any> {
    try {
        const response = await fetchWrapper.post(`${BASE_URL}/challenges/${id}/submission`, await utilsService.appendToFormData(data), { signal });
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const challengeService = {
    GetListChallenges,
    getChallengeDetails,
    getChallengesParticipant,
    saveTemporaryByStep,
    getOptionsSubmitWorkByType,
    getParticipantEd,
    deleteDraftWork,
    submitWork,
};

import { PostTypeConfig } from '@/app/constants/typedef';
import fetchWrapper from '../fetchWrapper';

const BASE_URL = process.env.BASE_URL;

interface ActionFeeds {
    type?: string;
    id: string;
    signal?: AbortSignal;
}

interface UpdateStatusPosts {
    type?: string;
    id: string;
    data: any;
    signal?: AbortSignal;
}

interface DeleteStatusPosts {
    id: string;
    type?: string;
    signal?: AbortSignal;
}

interface UpdateAPosts extends UpdateStatusPosts {}

async function likeFeeds({ type, id, signal }: ActionFeeds): Promise<any> {
    try {
        const response = await fetchWrapper.post(`${BASE_URL}/${type}/${id}/like`, { signal });
        return response;
    } catch (error) {
        throw error;
    }
}

async function likeComments({ id, signal }: ActionFeeds): Promise<any> {
    try {
        const response = await fetchWrapper.post(`${BASE_URL}/comments/${id}/like`, { signal });
        return response;
    } catch (error) {
        throw error;
    }
}

async function bookmarkFeeds({ type, id, signal }: ActionFeeds): Promise<any> {
    try {
        const response = await fetchWrapper.post(`${BASE_URL}/${type}/${id}/bookmark`, { signal });
        return response;
    } catch (error) {
        throw error;
    }
}

async function updateStatusFeedForOnlyPosts({ type = 'posts', id, data, signal }: UpdateStatusPosts): Promise<any> {
    try {
        const response = await fetchWrapper.patch(`${BASE_URL}/${type}/${id}/status`, data, { signal });
        return response;
    } catch (error) {
        throw error;
    }
}

async function deleteFeedForOnlyPosts({ type = 'posts', id }: DeleteStatusPosts): Promise<any> {
    try {
        const response = await fetchWrapper.delete(`${BASE_URL}/${type}/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}

async function reportPost({ type = 'posts', id }: {type: string, id: string}): Promise<any> {
    try {
        const response = await fetchWrapper.post(`${BASE_URL}/${type}/${id}/report`);
        return response;
    } catch (error) {
        throw error;
    }
}

async function hidePost({ type = 'posts', id }: {type: string, id: string}): Promise<any> {
    try {
        const response = await fetchWrapper.post(`${BASE_URL}/${type}/${id}/hide`);
        return response;
    } catch (error) {
        throw error;
    }
}

export const actionFeedServices = {
    likeFeeds,
    likeComments,
    bookmarkFeeds,
    updateStatusFeedForOnlyPosts,
    deleteFeedForOnlyPosts,
    reportPost,
    hidePost
};

import utilsService from '@/app/helpers/utilsService';
import fetchWrapper from '../fetchWrapper';

const BASE_URL = process.env.BASE_URL;

interface GetTopPostsParams {
    // limit?: number;
    cursor?: string | null;
    perPage?: number;
    signal?: AbortSignal;
}

interface BannersDetailParams {
    signal?: AbortSignal;
    page: 'home' | 'attendance' | 'roulette' | 'quiz';
    position: 'top';
}

async function getBanners({ page, position, signal }: BannersDetailParams): Promise<any> {
    try {
        const basePath = 'banners';
        const params = {
            page: page,
            position: position,
        };

        const url = utilsService.constructUrlWithParams(basePath, params);

        const response = await fetchWrapper.get(url, { signal });
        return response;
    } catch (error) {
        throw error;
    }
}
async function getExpert({ signal }: { signal?: AbortSignal }): Promise<any> {
    try {
        const response = await fetchWrapper.get(`${BASE_URL}/home/expert`, { signal });
        return response;
    } catch (error) {
        throw error;
    }
}

async function getTopPosts({ cursor = null, perPage = 20, signal }: GetTopPostsParams): Promise<any> {
    try {
        const basePath = 'home/top-articles';
        const params = {
            cursor: cursor,
            per_page: perPage,
        };

        const url = utilsService.constructUrlWithParams(basePath, params);

        const response = await fetchWrapper.get(url, { signal });
        return response;
    } catch (error) {
        throw error;
    }
}

async function getFaqHome(signal: AbortSignal): Promise<any> {
    try {
        const response = await fetchWrapper.get(`${BASE_URL}/home/faqs`, { signal });
        return response;
    } catch (error) {
        throw error;
    }
}

export const homeService = {
    getTopPosts,
    getBanners,
    getExpert,
    getFaqHome,
};

import { AbsoluteValue, ObjectType } from '@/app/constants/typedef';
import DataModel from '../abstract/DataModel';
import { Media } from '../media/MediaModel';
import { DateType } from '../date';
import { IUser } from '../user/IUser';

interface StatusComments {
    id: number;
    name: string;
}
export class Reply extends DataModel {
    public id: string | number;
    public commentAbleType: string;
    public commentAbleId: number;
    public owner: IUser;
    public content: string;
    public isLike: boolean;
    public likeCount: number;
    public media: Media[];
    public parentCommentId: string;
    public replies?: Reply[];
    public deletedAt?: string | null;
    public updatedAt: DateType;
    public isEdited: boolean;
    public status?: StatusComments;
    public isReported: boolean;

    constructor(data: ObjectType) {
        super(data);
        this.id = data.id;
        this.status = data.status;
        this.owner = data?.owner?.id
            ? {
                  id: data.owner.id,
                  nickname: data.owner.nickname,
                  avatarUrl: data.owner.avatar,
                  email: data.owner.email,
                  contacts: data.owner?.contacts,
                  isStaff: data.owner.is_staff,
                  isInfluencer: data.owner.is_influencer,
                  isExpert: data.owner.is_expert,
                  phoneNumber: data.owner.phone_number,
                  updatedAt: data.owner?.updated_at,
                  createdAt: data.owner?.created_at,
              }
            : {
                  id: 'unknown',
                  nickname: 'UNKNOWN',
                  avatarUrl: null,
                  email: '',
                  contacts: '',
                  isStaff: false,
                  isInfluencer: false,
                  isExpert: false,
                  phoneNumber: false,
                  updatedAt: null,
                  createdAt: null,
              };
        this.deletedAt = data.deleted_at;
        this.content = data.text;
        this.isLike = data.is_like;
        this.isReported = data.is_reported;
        this.parentCommentId = data.parent_comment_id;
        this.commentAbleId = data.commentable_id;
        this.commentAbleType = data.commentable_type;
        this.likeCount = data.like_count;
        this.media = data.media;
        this.updatedAt = data.updated_at;
        this.replies = data.replies?.length > AbsoluteValue.Number ? data.replies.map((reply: Reply) => new Reply(reply)) : [];
        this.isEdited = data.is_edited;
    }
}

export enum StatusComment {
    Normal = 1,
    isReported = 2,
    isBannedByAdmin = 3,
    isDeletedByOwner = 4,
    isDeletedByAdmin = 5,
}
import utilsService from '@/app/helpers/utilsService';
import fetchWrapper from '../fetchWrapper';

const BASE_URL = process.env.BASE_URL;

interface GetPostParams {
    cursor?: string | null;
    perPage?: number;
    signal?: AbortSignal;
    condition?: string | null;
}

interface GetPostDetailParams {
    postId: string | number;
    signal?: AbortSignal;
}

async function getPostsDetails({ postId, signal }: GetPostDetailParams): Promise<any> {
    try {
        const response = await fetchWrapper.get(`${BASE_URL}/posts/${postId}`, { signal });
        return response;
    } catch (error) {
        throw error;
    }
}
async function getPostLists({ cursor = null, condition = '', perPage = 20, signal }: GetPostParams): Promise<any> {
    try {
        const basePath = 'notice-board';
        const params = {
            cursor: cursor,
            per_page: perPage,
            group: condition !== 'common' ? condition : undefined,
        };

        const url = utilsService.constructUrlWithParams(basePath, params);

        const response = await fetchWrapper.get(url, { signal });
        return response;
    } catch (error) {
        throw error;
    }
}

export const postsService = {
    getPostsDetails,
    getPostLists,
};

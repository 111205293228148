import { CreateMedia } from '@/app/models/media/IMedia';
import fetchWrapper from '../fetchWrapper';
import { EditProfileRequest } from '@/app/models/user/IUser';
import utilsService from '@/app/helpers/utilsService';
import { DataAddressResponseSuccess, IAddressData, updateAddressDataRequest } from '@/app/models/address';
import { PointDataObject, PointDataResponseSuccess, PointDataResponseSuccessObject } from '@/app/models/points';
import { JoyDataResponseSuccess } from '@/app/models/response';
import { PaymentRequest, PaymentRequestMobile } from '@/app/models/payments';

const BASE_URL = process.env.BASE_URL;

interface AddressParams {
    id?: number;
    cursor?: any;
    perPage?: number;
    signal?: AbortSignal;
}
interface getListFeedsMyChannel {
    cursor?: string | null;
    perPage?: string | number;
    filter: 'posts' | 'bookmarks' | 'likes' | 'comments';
    sortBy: string;
    sortOrder: string;
    signal?: AbortController;
    partnerId?: string;
    groupId?: string;
}

async function editProfile(data: EditProfileRequest): Promise<any> {
    try {
        const response = await fetchWrapper.post(`${BASE_URL}/user/update-profile`, await utilsService.appendToFormData(data));
        return response.data;
    } catch (error) {
        throw error;
    }
}

async function editInformationMember(data: any): Promise<any> {
    try {
        const response = await fetchWrapper.post(`${BASE_URL}/user/update-info`, await utilsService.appendToFormData(data));
        return response.data;
    } catch (error) {
        throw error;
    }
}

async function getProfiles(signal?: AbortSignal): Promise<any> {
    try {
        const response = await fetchWrapper.get(`${BASE_URL}/user/profile`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

async function filterFeedsInMyChannelPage({ filter = 'posts', cursor = null, groupId = '', perPage = 20, sortBy = '', sortOrder = '', signal }: getListFeedsMyChannel): Promise<any> {
    try {
        const basePath = `/user/channel/${filter}`;
        const params = {
            per_page: perPage,
            sort_by: sortBy,
            sort_order: sortOrder,
            cursor: cursor,
            group: groupId,
        };

        const url = utilsService.constructUrlWithParams(basePath, params);

        const response = await fetchWrapper.get(url, { signal });
        return response;
    } catch (error) {
        throw error;
    }
}

async function filterFeedsInMyChannelForPartnerPage({
    partnerId = '',
    filter = 'posts',
    groupId = '',
    cursor = null,
    perPage = 20,
    sortBy = '',
    sortOrder = '',
    signal,
}: getListFeedsMyChannel): Promise<any> {
    try {
        const basePath = `partners/${partnerId}/${filter}`;
        const params = {
            per_page: perPage,
            sort_by: sortBy,
            sort_order: sortOrder,
            cursor: cursor,
            group: groupId !== 'common' ? groupId : undefined,
        };
        const url = utilsService.constructUrlWithParams(basePath, params);
        const response = await fetchWrapper.get(`${url}`, { signal });
        return response;
    } catch (error) {
        throw error;
    }
}

async function getInvitationCode(): Promise<any> {
    try {
        const response = await fetchWrapper.get(`${BASE_URL}/user/invitation`);
        return response;
    } catch (error) {
        throw error;
    }
}

async function getInfoPartner(id: string): Promise<any> {
    try {
        const response = await fetchWrapper.get(`${BASE_URL}/partners/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}

async function getFAQType(): Promise<any> {
    try {
        const response = await fetchWrapper.get(`${BASE_URL}/user/faqs/types`);
        return response;
    } catch (error) {
        throw error;
    }
}

async function getFAQs({ type = '', cursor = null, perPage = 20, signal }: { type: string; cursor: string | null; perPage: number; signal?: AbortSignal }): Promise<any> {
    try {
        const basePath = 'user/faqs';
        const params = {
            cursor: cursor,
            per_page: perPage,
            type: type,
        };

        const url = utilsService.constructUrlWithParams(basePath, params);

        const response = await fetchWrapper.get(url, { signal });
        return response;
    } catch (error) {
        throw error;
    }
}

async function readFAQ(id: string | number): Promise<any> {
    try {
        const response = await fetchWrapper.post(`${BASE_URL}/user/faqs/${id}/read`);
        return response;
    } catch (error) {
        throw error;
    }
}

async function getAnnouncements({ cursor = null, perPage = 20, signal }: { cursor: string | null; perPage: number; signal?: AbortSignal }): Promise<any> {
    try {
        const basePath = 'user/announcements';
        const params = {
            cursor: cursor,
            per_page: perPage,
        };

        const url = utilsService.constructUrlWithParams(basePath, params);

        const response = await fetchWrapper.get(url, { signal });
        return response;
    } catch (error) {
        throw error;
    }
}

async function getHistoryPoint({
    cursor = null,
    perPage = 20,
    date = '',
    type = '1',
    signal,
}: {
    cursor: string | null;
    perPage: number;
    date: string;
    type: string;
    signal?: AbortSignal;
}): Promise<PointDataResponseSuccessObject> {
    try {
        const basePath = '/user/statistic/points';
        const params = {
            cursor: cursor,
            per_page: perPage,
            date: date,
            type: type,
        };

        const url = utilsService.constructUrlWithParams(basePath, params);

        const response = await fetchWrapper.get(url, { signal });
        const camelizedResponse = utilsService.toCamelCase(response, { deep: true });
        return camelizedResponse;
    } catch (error) {
        throw error;
    }
}

async function readAnnouncements(id: string | number): Promise<any> {
    try {
        const response = await fetchWrapper.post(`${BASE_URL}/user/announcements/${id}/read`);
        return response;
    } catch (error) {
        throw error;
    }
}

async function getMyStatistic(signal: AbortSignal): Promise<any> {
    try {
        const response = await fetchWrapper.get(`${BASE_URL}/user/statistic`, { signal });
        return response;
    } catch (error) {
        throw error;
    }
}

async function getPoints(signal: AbortSignal): Promise<any> {
    try {
        const response = await fetchWrapper.get(`${BASE_URL}/point`, { signal });
        return response;
    } catch (error) {
        throw error;
    }
}

async function getListsAddressUser({ cursor, perPage, signal }: AddressParams): Promise<any> {
    const basePath = 'user/delivery-address';
    try {
        const response = await fetchWrapper.get(`${BASE_URL}/${basePath}`, { signal });
        const camelizedResponse = utilsService.toCamelCase(response, { deep: true });
        return camelizedResponse;
    } catch (error) {
        throw error;
    }
}

async function getAddressById({ id, signal }: AddressParams): Promise<DataAddressResponseSuccess> {
    const basePath = 'user/delivery-address';
    try {
        const response = await fetchWrapper.get(`${BASE_URL}/${basePath}/${id}`, { signal });
        const camelizedResponse = utilsService.toCamelCase(response, { deep: true });
        return camelizedResponse;
    } catch (error) {
        throw error;
    }
}

async function createAddress(data: IAddressData): Promise<DataAddressResponseSuccess> {
    const basePath = 'user/delivery-address';
    try {
        const response = await fetchWrapper.post(`${BASE_URL}/${basePath}`, await utilsService.appendToFormData(data));
        const camelizedResponse = utilsService.toCamelCase(response?.data, { deep: true });
        return camelizedResponse;
    } catch (error) {
        throw error;
    }
}

async function updateAddress(data: updateAddressDataRequest): Promise<DataAddressResponseSuccess> {
    const basePath = 'user/delivery-address';
    try {
        const response = await fetchWrapper.patch(`${BASE_URL}/${basePath}/${data?.id}`, await utilsService.appendToFormData(data));
        const camelizedResponse = utilsService.toCamelCase(response?.data, { deep: true });
        return camelizedResponse;
    } catch (error) {
        throw error;
    }
}

async function deleteSingleAddress(id: number): Promise<any> {
    const basePath = 'user/delivery-address';
    try {
        const response = await fetchWrapper.delete(`${BASE_URL}/${basePath}/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}

async function getTotalOrder(): Promise<any> {
    const basePath = 'user/myorders/total';
    try {
        const response = await fetchWrapper.get(`${BASE_URL}/${basePath}`);
        return response;
    } catch (error) {
        throw error;
    }
}

async function getPaymentDummyData({ platform = 'mobile' }: { platform: 'mobile' | 'desktop' }): Promise<JoyDataResponseSuccess<PaymentRequest> | JoyDataResponseSuccess<PaymentRequestMobile>> {
    const basePath = 'payments';
    try {
        const response = await fetchWrapper.post(`${BASE_URL}/${basePath}?platform=${platform}`);
        const camelizedResponse = utilsService.toCamelCase(response?.data, { deep: true });
        return platform === 'desktop' ? (camelizedResponse as JoyDataResponseSuccess<PaymentRequest>) : (camelizedResponse as JoyDataResponseSuccess<PaymentRequestMobile>);
    } catch (error) {
        throw error;
    }
}

async function postPaymentDataReceived(data: any): Promise<any> {
    const basePath = 'payments/callback';
    try {
        const response = await fetchWrapper.post(`${BASE_URL}/${basePath}`, data);
        const camelizedResponse = utilsService.toCamelCase(response?.data, { deep: true });
        return camelizedResponse;
    } catch (error) {
        throw error;
    }
}

export const myChannelService = {
    editProfile,
    editInformationMember,
    getPoints,
    getProfiles,
    getInfoPartner,
    filterFeedsInMyChannelPage,
    filterFeedsInMyChannelForPartnerPage,
    getInvitationCode,
    getFAQType,
    getFAQs,
    readFAQ,
    getMyStatistic,
    getAnnouncements,
    readAnnouncements,
    getHistoryPoint,
    getListsAddressUser,
    getAddressById,
    createAddress,
    updateAddress,
    deleteSingleAddress,
    getTotalOrder,
    getPaymentDummyData,
    postPaymentDataReceived
};
